import * as React from "react";
import { FormattedMessage, useIntl } from "gatsby-plugin-react-intl";
import TextPage, { TextBlock } from '../components/text-page';
import commonMessageValues from '../templates/formatjs-message-values';

const SMSMobileBanking = (): JSX.Element => {
  const intl = useIntl();
  const messages = intl.messages;
  const textBlocks: TextBlock[] = [{
    header: `${messages.sms_banking}`,
    message: <FormattedMessage id="message_sms_banking" values={commonMessageValues}/>
  }, {
    header: `${messages.mobile_banking}`,
    message: (
      <>
        <FormattedMessage id='message_mobile_banking' values={commonMessageValues}/>
        <div className="ui bulleted list">
          <div className="item">{messages.message_mobile_banking_item1}</div>
          <div className="item">{messages.message_mobile_banking_item2}</div>
          <div className="item">{messages.message_mobile_banking_item3}</div>
          <div className="item">{messages.message_mobile_banking_item4}</div>
          <div className="item">{messages.message_mobile_banking_item5}</div>
        </div>
      </>
    )
  }];

  return (<TextPage locale={intl.locale} title={`${messages.sms_and_mobile_banking}`} textBlocks={textBlocks}/>);
};

export default SMSMobileBanking;